import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

import './App.css'

// Lazy loading des composants
// const Home = lazy(() => import('./views/Home'));
// import Home from './views/Home'; 
const Home = lazy(() => import('./views/Home'));
const Server = lazy(() => import('./views/Server'));
const Callback = lazy(() => import('./views/Callback'));
const Logout = lazy(() => import('./views/Logout'));

const ConfigIa = lazy(() => import('./views/ConfigIa'))
const ConfigBump = lazy(() => import('./views/ConfigBump'))

const App = () => {
  return (
    <Router>
      <Suspense>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />

          <Route path="/callback" element={<Callback />} />

          {/* <Route path="/manage/user" element={<User />} /> */}
          <Route path="/:g_id" element={<Server />} />
          <Route path="/:g_id/" element={<Server />} />

          <Route path="/:g_id/ia" element={<ConfigIa />} />
          <Route path="/:g_id/ia/*" element={<ConfigIa />} />

          <Route path="/:g_id/bump" element={<ConfigBump />} />
          <Route path="/:g_id/bump/*" element={<ConfigBump />} />

          <Route path="/logout" element={<Logout />} />
          <Route path="/logout/" element={<Logout />} />
          {/* <Route path="/:guild_id/" element={<Dashboard />} /> */}
          {/* <Route path="/:guild_id/bump" element={<BumpConfig />} /> */}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
